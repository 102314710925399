<template>
  <div
    class="section-container"
    :class="{ 'section-container--bordered': isBordered, 'section-container--full-width': isFullWidth }"
  >
    <div v-if="hasHeader" class="section-container__header">
      {{ title }}
    </div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SectionContainer',
  props: {
    title: {
      type: String,
      default: '',
    },
    isBordered: {
      type: Boolean,
      default: false,
    },
    hasHeader: {
      type: Boolean,
      default: false,
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.section-container {
  width: 100%;
  max-width: 55rem;
  border-radius: 0.25rem;
  min-height: 19.87rem;

  &--bordered {
    border: solid 2px #7ed321;
  }

  &--full-width {
    max-width: 100%;
    width: 100%;
  }

  &__header {
    font-family: Avenir-Pro-Medium, sans-serif;
    font-size: 1.25rem;
    color: #454545;
    background-color: #f6f6f6;
    padding: 0.3rem 0;
  }
}
</style>
